/**
 * 系统管理 用户管理
 */
<template>
  <div>
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :search-form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="handleEdit">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="userData"
        :operation-button="operationButton"
        :loading="loading"
        :operation-button-width="260"
      >
        <template #userCode="{ row }">
          <span>{{ row.userCode }}</span>
          <span v-if="row.isAdmin==='1'" class="adminButton" size="small" type="primary" plain>
            超级管理员
          </span>
        </template>
      </Table>
      <!-- 分页组件 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="userData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 选择角色 -->
    <GetRole ref="getRole" :edit-check-role-list="editForm.roleCode" @getCheckRole="getCheckRole" />
    <!-- 弹出的新增跟修改框 -->
    <Dialog ref="dialog" dialog-width="40%" :add-and-edit-form-item="addAndEditFormItem" :edit-form-data="editForm" @getFormData="getFormData">
      <template slot="roleCode">
        <div class="authorize">
          <i v-if="editForm.roleCode.length > 0" class="el-icon-success" /> <span v-else @click="getRole">去授权</span><span v-if="editForm.roleCode.length>0" @click="getRole">查看授权</span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import Table from '@/components/Table'
import FormSearch from '@/components/FormSearch'
import { formPattern } from '@/unit/matchFormRegex'
// 导入请求方法
import { baseinfoChangAdmin, userEnabledAndStop, getAllRoles, userResetPassword, getUserByPage, addAndUpdataUser } from '@/api/institutionalFramework'
import Pagination from '@/components/Pagination'
import GetRole from './components/getRole.vue'
export default {
  // 注册组件
  components: {
    Pagination,
    Dialog,
    Table,
    FormSearch,
    GetRole
  },
  data() {
    const validateRoleCode = (rule, value, callback) => {
      if (this.editForm.roleCode.length === 0) {
        callback(new Error('请选择'))
      } else {
        callback()
      }
    }
    var validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
      }
      callback()
    }
    return {
      roleArr: [],
      organizationArr: [],
      searchFormItemArr: [
        { type: 'input', label: '登录账号', value: 'userCode' },
        { type: 'input', label: '真实姓名', value: 'userName' },
        { type: 'input', label: '手机号', value: 'phoneNo' },
        { type: 'input', label: '邮箱', value: 'email' },
        { type: 'select', label: '角色', value: 'roleCode', pLabel: 'roleName', pValue: 'roleCode', child: [] }
        // { type: 'select', label: '机构', value: 'orgCode', pLabel: 'orgName', pValue: 'orgCode', child: [] }
      ],
      total: 0,
      itemData: [
        { label: '登录账号', prop: 'userCode', width: 160 },
        // { label: '机构', prop: 'orgName', width: 120 },
        { label: '角色', prop: 'roleName', width: 260, html: true, limited: true },
        { label: '真实姓名', prop: 'userName', width: 120 },
        { label: '手机号', prop: 'phoneNoHide', width: 120 },
        // { label: '身份证号', value: 'cardNo', width: 120 },
        // { label: '性别', prop: 'sex', width: 80, child: [{ id: '0', name: '男' }, { id: '1', name: '女' }] },
        { label: '状态', prop: 'isLock', width: 80, child: [{ id: '0', name: '启用' }, { id: '1', name: '禁用' }] },
        { label: '邮箱', prop: 'email', width: 120 }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.handleEdit },
        { bType: 'primary', label: '重置密码', handleEvent: this.resetpwd },
        { bType: 'primary', label: '设为超级管理员', handleEvent: this.setSuperAdministrator, child: [{ val: 'status' }] },
        { num: '1', val: 'isLock', bType: 'success', label: '启用', handleEvent: this.offlineUser },
        { num: '0', val: 'isLock', bType: 'danger', label: '禁用', handleEvent: this.offlineUser }
      ],
      addAndEditFormItem: [
        { label: '登录账号', type: 'input', value: 'userCode', rules: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }] },
        { label: '真实姓名', type: 'input', value: 'userName' },
        // { type: 'input', label: '身份证号', value: 'cardNo', rules: [...rules.cardVerification] },
        {
          label: '角色',
          type: 'select',
          slotName: 'roleCode',
          value: 'roleCode',
          rules: [{ required: true, message: '请选择', validator: validateRoleCode, trigger: ['blur', 'change'] }],
          child: []
        },
        // {
        //   label: '机构',
        //   type: 'select',
        //   value: 'subOrgCode',
        //   optionLabel: 'orgName',
        //   optionId: 'orgCode',
        //   child: []
        // },
        { label: '手机号', type: 'input', value: 'phoneNo', rules: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { ...formPattern.tel }
        ] },
        { label: '邮箱', type: 'input', value: 'email', rules: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            required: true,
            message: '请输入正确的邮箱',
            trigger: 'blur'
          }
        ] }
        // { label: '性别', type: 'radio', value: 'sex', child: [{ label: '男', value: '0' }, { label: '女', value: '1' }] }
      ],
      loading: false, // 是显示加载
      // 编辑与添加
      editForm: {
        roleCode: []
      },
      // 请求数据参数
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      // 用户数据
      userData: []
    }
  },
  created() {
    getAllRoles(this.formInline.roleName, res => {
      this.addAndEditFormItem.map(item => {
        if (item.value === 'roleCode') {
          item.child = [...res.data]
        }
      })
      this.searchFormItemArr.map(item => {
        if (item.value === 'roleCode') {
          item.child = [...res.data]
        }
      })
    })
    // getOrganizationList(res => {
    //   this.addAndEditFormItem.map(item => {
    //     if (item.value === 'subOrgCode') {
    //       item.child = [...res.data]
    //     }
    //   })
    //   // this.searchFormItemArr.map(item => {
    //   //   if (item.value === 'orgCode') {
    //   //     item.child = [...res.data]
    //   //   }
    //   // })
    // })
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 设为超级管理员
    async setSuperAdministrator(row) {
      const confirm = await this.$confirm(`此操作将 ${row.userName} 设为超级管理员，是否继续？`, '提示', { type: 'info' }).catch(err => err)
      if (confirm !== 'confirm') return
      baseinfoChangAdmin({ userCode: row.userCode }, () => {
        this.$message.success('成功！')
        this.getdata(true)
      })
    },
    // 打开角色弹出层
    getRole() {
      if (this.editForm.isAdmin === '1') {
        this.$message.error('您已经是超级管理员，无需在进行角色授权')
        return
      }
      this.$refs.getRole.authorityManagementVisible = true
    },
    // 获取角色
    getCheckRole(value) {
      this.editForm.roleCode = [...value]
    },
    // 获取数据方法
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      this.loading = true
      getUserByPage(this.formInline, res => {
        this.total = res.data.total
        this.userData = [...res.data.list]
        // 给角色赋值啊 好难哦
        this.userData.forEach(item => {
          item.roleName = ''
          if (item.isAdmin !== '1' && item.isLock === '0') {
            item.status = true
          } else {
            item.status = false
          }
          if (item.cmpRoleAndRoleName && item.cmpRoleAndRoleName.length > 0) {
            item.cmpRoleAndRoleName.forEach(val => {
              // 如果是为0的就置灰  不是的就变正常颜色
              if (val.roleCodeNames && val.roleCodeNames.length > 0) {
                val.roleCodeNames.forEach(lable => {
                  item.roleName += `<span style='${val.delFlag === 1 ? 'color:#333;' : 'color:#93959A;'}'>${this.getPlatform(val.cmpRole)}-${lable}</span><br>`
                })
              }
            })
          }
        })
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    // 获取是哪个平台的
    getPlatform(code) {
      if (!code) return
      const sycArr = this.$store.getters.getDictionaryItem('SYS_TYPE') || []
      const obj = sycArr.find(item => code === item.dictId)
      return obj.dictName
    },
    // 显示编辑界面
    handleEdit(row) {
      this.$refs.dialog.editFormVisible = true
      if (row.constructor === Object) {
        const arr = []
        if (row.cmpRoleAndRoleName.length > 0) {
          row.cmpRoleAndRoleName.forEach(item => {
            if (item.roleCodes && item.roleCodes.length > 0) {
              item.roleCodes.forEach(lable => {
                arr.push({ roleCode: lable, usePlatform: item.cmpRole })
              })
            }
          })
        }
        this.editForm = { ...row, roleCode: [...arr] }
        delete this.editForm.createTime
        delete this.editForm.modifyTime
      } else {
        this.editForm = {
          roleCode: []
        }
      }
    },
    // 编辑、添加提交方法
    getFormData(value) {
      this.loading = true
      // 请求方法
      value.subOrgCode = ''
      value.orgCode = JSON.parse(localStorage.getItem('userInfoWms')).cmpId
      addAndUpdataUser({ ...value, roleCode: [...this.editForm.roleCode] }, () => {
        this.loading = false
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
        this.getdata(true)
      }, () => {
        this.loading = false
      })
    },
    // 重置密码
    resetpwd(row) {
      this.$confirm('确定要重置密码吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userResetPassword(row.userCode, () => {
            this.$message.success('重置密码成功！')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消重置密码！'
          })
        })
    },
    // 下线用户
    offlineUser(row) {
      this.$confirm(`确定要让${row.userName}用户${parseInt(row.isLock) === 1 ? '启用' : '禁用'}吗?`, '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userEnabledAndStop({ params: { userCode: row.userCode, isLock: parseInt(row.isLock) === 1 ? 0 : 1 }}, res => {
          this.getdata(true)
          this.$message.success('成功')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-search {
  margin-top: 20px;
}
.user-search .el-input,
.user-search .el-select {
  width: 180px;
}
.adminButton{
  margin-left: 6px;
  border: 1px solid #e3e3e3;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  cursor: pointer;
}
.userRole {
  width: 100%;
}
.color1{
  color: $mainFontColor;
}
.color2{
  color: $assistColor7;
}
.authorize{
    color: $assistColor4;
    i{
      color: $assistColor4;
      margin-right: 12px;
    }
    span{
      color: $assistColor4;
      cursor: pointer;
    }
  }
</style>

